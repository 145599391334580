import {Text, Heading} from '@chakra-ui/react'

import styled from 'styled-components'

export const StyledTitle = styled(Heading)`
  color: var(--heading-color);
`
export const StyledText = styled(Text)`
  color: var(--body-color);
`

export const StyledInlineDiv = styled.div`
  display: 'inline-block';
`
