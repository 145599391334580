import React from 'react'
// import { motion } from "framer-motion";
import {Box} from '@chakra-ui/react'
import {useBreakpointValue} from '@chakra-ui/react'
import Particles, {ParticlesProps} from 'react-particles-js'
import {css} from '@emotion/react'

export const ParticlesBg = (props: ParticlesProps): JSX.Element => {
  const numOfParticles = useBreakpointValue<number>({base: 30, md: 100}) || 60

  return (
    <Box position="absolute" top="0" bottom="0" left="0" right="0">
      <Particles
        params={SimplePreset({numOfParticles})}
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100vh',
          flex: 1,
          zIndex: -1,
          // backgroundImage: `url(${logo})`
        }}
        className={
          css`
            position: relative;
            width: 100%;
          `.name
        }
        {...props}
      />
    </Box>
  )
}

type PresetOptions = {
  numOfParticles: number
}

const SimplePreset = ({numOfParticles}: PresetOptions) => ({
  particles: {
    number: {
      value: numOfParticles,
      density: {
        value_area: 1000,
      },
    },
    size: {
      value: 5,
      random: true,
    },
    shape: {
      // type: 'star', // star  | circle
    },
    move: {
      speed: 2,
    },
    line_linked: {
      enable: false,
      distance: 30,
      shadow: {
        enable: true,
        color: '#3C5991',
        blur: 5,
      },
      color: '#fff',
      opacity: 0.6,
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
      onclick: {
        enable: true,
        mode: 'push',
      },
    },
  },
})

const BubblesPreset = {
  particles: {
    number: {
      value: 160,
      density: {
        enable: false,
      },
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 4,
        size_min: 0.3,
      },
    },
    line_linked: {
      enable: false,
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out',
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble',
      },
      onclick: {
        enable: true,
        mode: 'repulse',
      },
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 0,
      },
      repulse: {
        distance: 400,
        duration: 4,
      },
    },
  },
}

const SnowPreset = {
  particles: {
    number: {
      value: 160,
      density: {
        enable: false,
      },
    },
    size: {
      value: 10,
      random: true,
    },
    move: {
      direction: 'bottom',
      out_mode: 'out',
    },
    line_linked: {
      enable: false,
    },
  },
  interactivity: {
    events: {
      onclick: {
        enable: true,
        mode: 'remove',
      },
    },
    modes: {
      remove: {
        particles_nb: 10,
      },
    },
  },
}
