import {
  TwitterCircleFilled,
  GithubFilled,
  LinkedinFilled,
} from '@ant-design/icons'

export const SOCIAL_LINKS = {
  github: {
    profileName: 'Pankaj Vaghela',
    url: 'https://github.com/pankajvaghela',
    platform: 'github',
    Icon: GithubFilled,
  },
  linkedin: {
    profileName: 'Pankaj Vaghela',
    url: 'https://in.linkedin.com/in/iampankajvaghela',
    platform: 'linkedin',
    Icon: LinkedinFilled,
  },
  twitter: {
    profileName: 'Pankaj Vaghela',
    url: 'https://twitter.com/iampankajv1',
    platform: 'twitter',
    Icon: TwitterCircleFilled,
  },
}
