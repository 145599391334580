import React from 'react'
import PankajImg from './../../common/images/pankaj-vaghela-portrait_dakshana-low.jpg'
import {
  StyledTitle,
  StyledInlineDiv,
  StyledText,
} from './../../common/components/Typography'
import styled from 'styled-components'
import {
  Box,
  Divider,
  Flex,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
} from '@chakra-ui/layout'
import {useBreakpointValue} from '@chakra-ui/media-query'
// import {Layout, Row, Col} from '@chakra-ui/react'

import {CheckCircleIcon} from '@chakra-ui/icons'

type PersonalInfoViewProps = Map<string, unknown>
// width: 90%;
// max-width: 450px;

const StyledImage = styled.img`
  height: 90%;
`

const PersonalDataWrapper = styled.div`
  margin-top: 20px;
`

const FullScreenSection = styled.div`
  @media (min-width: 600px) {
    min-height: 100vh;
  }
`

export const PersonalInfoView = React.forwardRef<PersonalInfoViewProps>(
  function PersonalInfoView(props, ref) {
    const numOfColumns = useBreakpointValue({base: 1, sm: 2})

    return (
      <Box
        style={{
          minHeight: '600px',
          background:
            'linear-gradient(150deg, rgba(19,18,32,1) 0%, rgba(27,26,42,0.94) 35%, rgba(6,6,9,0.4) 69%, rgba(17,17,24,0.02) 100%)',
        }}
        pt={20}
        pb={numOfColumns === 1 ? 20 : 15}>
        <SimpleGrid
          columns={numOfColumns}
          justify="space-around"
          style={{flex: 1, padding: '10px 20px'}}>
          <Box
            align="center"
            style={{
              paddingTop: '20px',
            }}>
            <StyledImage
              src={PankajImg}
              alt="Pankaj Vaghela - During Cultural Event"
              style={{
                objectFit: 'cover',
                maxWidth: '400px',
                aspectRatio: '1',
              }}
            />
          </Box>
          <Box align={numOfColumns === 1 ? 'center' : 'flex-start'}>
            <PersonalDataWrapper>
              <Box>
                <StyledTitle
                  size="lg"
                  pb={2}
                  pr={10}
                  pl={{base: 10, md: 0}}
                  borderBottom="1px solid white"
                  d="inline-block">
                  Pankaj Vaghela
                </StyledTitle>
              </Box>

              <Box d="inline-block" style={{textAlign: 'left'}}>
                <Box>
                  <List
                    mt={5}
                    colorScheme="teal"
                    d="flex"
                    flexDirection="column"
                    alignItems="flex-start">
                    {[
                      `B.Tech. (Hons.) Computer Science`,
                      `2+ years of industry experience`,
                      `3 Apps live on PlayStore`,
                      // 'Portrait Artist',
                      // 'Photographer',
                    ].map(item => (
                      <ListItem key={item} mr={2} my={2}>
                        <Box>
                          <ListIcon as={CheckCircleIcon} color="yellow.500" />
                          <StyledText as="span">{item}</StyledText>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <List
                  mt={10}
                  colorScheme="teal"
                  d="flex"
                  flexDirection="column"
                  alignItems="flex-start">
                  {[
                    `React | Web Developer`,
                    `React Native | Android App Developer`,
                    `Node & Laravel Backend Developer`,
                    `UI/UX Enthusiast`,
                    // 'Portrait Artist',
                    // 'Photographer',
                  ].map(item => (
                    <ListItem key={item} mr={2} my={2}>
                      <Box>
                        <ListIcon as={CheckCircleIcon} color="yellow.500" />
                        <StyledText as="span">{item}</StyledText>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>

              {/* <div>
                  <div style={{ display: "inline-block" }}>
                    <Text>
                      Mobile App and Full-Stack Web Developer, UI/UX enthusiast,{" "}
                    </Text>
                  </div>{" "}
                </div> */}
              {/* <div>
                    <div style={{display: 'inline-block'}}>
                      <StyledText type="secondary">
                        part-time Artist and Photographer
                      </StyledText>
                    </div>
                  </div> */}
            </PersonalDataWrapper>
          </Box>
        </SimpleGrid>
      </Box>
    )
  },
)
