export * from './const'
export * from './url'

const cloudinary_root = 'https://res.cloudinary.com/monads/image/upload/'
export const transformCloudinaryImage = (
  url: string,
  customs: string,
): string => {
  if (url.includes('upload/v')) {
    return url.replace(cloudinary_root, cloudinary_root + customs)
  } else if (url.includes('upload/f_auto')) {
    return url.replace(cloudinary_root + 'f_auto/', cloudinary_root + customs)
  }
  return url
}
