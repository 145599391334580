import React from 'react'

import {
  StyledTitle,
  StyledInlineDiv,
  StyledText,
} from './../../common/components/Typography'
import styled from 'styled-components'
import {
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Text,
  Image,
  Tag,
  Link,
} from '@chakra-ui/react'
import {LinkIcon} from '@chakra-ui/icons'

import {transformCloudinaryImage} from '../../common'

// import {useBreakpointValue} from '@chakra-ui/media-query'

type ProjectsViewProps = Map<string, unknown>
// width: 90%;
// max-width: 450px;

export const ProjectsView = React.forwardRef<ProjectsViewProps>(
  function ProjectsView() {
    // const numOfColumns = useBreakpointValue({base: 1, sm: 2})

    return (
      <Box
        as="section"
        id="projects"
        style={{
          minHeight: '600px',
          background:
            'linear-gradient(150deg, rgba(19,18,32,1) 0%, rgba(27,26,42,0.94) 35%, rgba(6,6,9,0.4) 69%, rgba(17,17,24,0.02) 100%)',
        }}
        pt={20}
        pb={15}>
        <Center>
          <StyledTitle
            pb={2}
            px={10}
            borderBottom="1px solid #ccc"
            d="inline-block">
            {'Projects'.toUpperCase()}
          </StyledTitle>
        </Center>

        <Box px={{base: 10, md: 35}} my={16}>
          <Grid
            templateColumns="repeat(auto-fit, minmax(250px, 350px))"
            justifyContent="center"
            alignItems="center"
            gap={10}>
            {ProjectsList.map((project, index) => (
              <Box key={index}>
                <Box
                  // borderRadius={5}
                  bg="#112"
                  p={0}
                  shadow={'lg'}
                  borderRadius="lg"
                  // width="sm"
                  // minHeight="sm"
                  border="1px solid #112"
                  overflow="hidden">
                  {!project.noPreview && (
                    <Box
                      position="relative"
                      className="img-dark-overlay-wrapper">
                      <Image
                        size="100px"
                        // fallbackSrc="https://via.placeholder.com/150"
                        src={transformCloudinaryImage(
                          project.previewImg[0],
                          'c_scale,f_auto,q_auto:eco,w_560',
                        )}
                        width="100%"
                        height="auto"
                        minHeight="200px"
                        alt={project.title}
                      />
                      <div className="img-dark-overlay" />
                    </Box>
                  )}
                  <Box p={4}>
                    <Flex my={1} justify="space-between" align="flex-start">
                      <StyledText fontSize="md" type="secondary">
                        {project.title}
                      </StyledText>
                      {!!project.url && (
                        <Link
                          href={project.url}
                          target="_blank"
                          rel="noopener"
                          title={project.title}>
                          <LinkIcon color="whiteAlpha.500" />
                        </Link>
                      )}
                    </Flex>

                    <Divider mb={2} mt={1} colorScheme="blue" />

                    <Flex my={1} justify="space-between" align="center">
                      <Text fontSize="sm" color="gray.200">
                        {project.company}
                      </Text>
                      <Text fontSize="xs" color="gray.300">
                        {project.duration}
                      </Text>
                    </Flex>
                    <Text fontSize="xs" color="gray.400">
                      {project.position}
                    </Text>

                    <Flex d="flex" flexWrap="wrap" my={2} mt={3}>
                      {project.tags.map((tag, i) => (
                        <Tag
                          key={i}
                          mr={2}
                          my={1}
                          size="sm"
                          colorScheme="purple">
                          {tag}
                        </Tag>
                      ))}
                    </Flex>
                  </Box>
                </Box>
              </Box>
            ))}
          </Grid>
        </Box>
      </Box>
    )
  },
)

//c_scale,f_auto,q_auto:eco,w_1280

const ProjectsList = [
  {
    title: 'IIIT Ranchi Official Website',
    company: 'IIIT Ranchi',
    position: 'Student Coordinator & Team Lead',
    duration: 'June 2019 - July 2021',
    url: 'http://iiitranchi.ac.in',
    tags: ['React', 'Laravel', 'GraphQL', 'Docker', 'AWS'],
    previewImg: [
      'https://res.cloudinary.com/lotivo/image/upload/v1617385831/creative_lotus_site/iiitr-homepage_t5weev.png',
    ],
  },
  {
    title: 'ZCash - An Instant Loan App',
    url: '',
    company: 'Zavron Finserv',
    position: 'Software Developer Intern @MonadsTech',
    duration: 'Jul 2020 - March 2021',
    tags: ['React Native', 'Native Modules', 'Fin-Tech'],
    previewImg: [
      'https://res.cloudinary.com/lotivo/image/upload/v1617389180/creative_lotus_site/zencash_nzauac.png',
      // 'https://res.cloudinary.com/lotivo/image/upload/c_scale,f_auto,q_auto:eco,w_1280/v1617385831/creative_lotus_site/iiitr-homepage_t5weev.png',
    ],
    isInProgress: true,
  },
  {
    title: 'OneMenu - Hospitality App',
    url: 'https://onemenu.ai/products/guest-app',
    company: 'OneMenu',
    position: 'Software Developer Intern @MonadsTech',
    duration: 'May 2020 - Jul 2020',
    tags: ['React Native', 'Redux', 'Hospitality', 'EComm'],
    previewImg: [
      'https://res.cloudinary.com/lotivo/image/upload/v1617389180/creative_lotus_site/onemenu_a9ydfl.png',
    ],
  },
  {
    title: 'React Native Form',
    summary: 'Declarative Form library for React Native ',
    url: 'https://npmjs.com/package/@monadstech/react-native-form',
    company: 'MonadsTech',
    position: 'Software Developer Intern @MonadsTech',
    duration: '2020',
    tags: ['React Native', 'Form', 'OpenSource'],
    noPreview: true,
    previewImg: [],
    contentPoints: [
      'Easy, Declarative, fluent  API',
      'Declarative Error handling.',
    ],
  },
  {
    title: 'Sequelize Guard',
    summary: 'An Authorization library for Sequelize.js',
    url: 'https://github.com/lotivo/sequelize-guard',
    company: 'Personal Project',
    position: 'Backend Developer',
    duration: '2020 - present',
    tags: ['Node', 'Sequelize', 'Authorization', 'OpenSource'],
    noPreview: true,
    previewImg: [],
    contentPoints: [
      'Easy authorization with fluent  API',
      'Role-permission based authorization.',
      'Caching mechanism for faster resolution.',
    ],
  },
  {
    title: 'Crystal PHP Framework',
    summary: 'MVC Framework written in PHP',
    url: 'https://github.com/CrystalCollective/CrystalPHP',
    company: 'Personal Project',
    position: 'Backend Developer',
    duration: '2019',
    tags: ['PHP', 'MVC', 'Routing', 'Framework', 'OpenSource'],
    noPreview: true,
    previewImg: [],
    contentPoints: [
      'Inspired from CodeIgniter and Laravel',
      'Smart Route based response using a Crystal Router',
      'Controller based route resolution available',
      'Supports Modular MVC or Hierarchical MVC',
      'Includes Tools for better SEO',
      'Easy setup for building RESTful API',
    ],
  },
  {
    title: 'Quick Screenshot App',
    summary: 'Android app for taking screenshots ',
    url:
      'http://play.google.com/store/apps/details?id=com.softatiks.screenshotcapture',
    company: 'Personal Project',
    position: 'Android Developer',
    duration: '2017',
    tags: ['Android', 'Java', 'Screen Capture', 'Service Management'],
    noPreview: true,
    previewImg: [],
    contentPoints: [
      'Makes taking rapid screenshots very easy due to slim design.',
      'Using a persistent service to display views over other apps to render a widget to take screenshots.',
      'Using Screen Recorder API for capturing screen',
      'Using Firebase Notification API for showing clicked screenshots & RealtimeDB service for app update notification',
    ],
  },
  // {
  //   title: 'Tickets4sure',
  //   url: '',
  //   company: 'Tickets4sure',
  //   position: 'Software Developer',
  //   duration: '2018 - 2019',
  //   tags: ['react', 'laravel'],
  //   previewImg: [
  //     'https://res.cloudinary.com/lotivo/image/upload/v1617385831/creative_lotus_site/iiitr-homepage_t5weev.png',
  //   ],
  // },
]
