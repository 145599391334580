import React from 'react'
import {
  Box,
  Link,
  Text,
  useBreakpointValue,
  Button,
  Icon,
  Container,
  Center,
  Flex,
  HStack,
} from '@chakra-ui/react'
import {ArrowDownIcon} from '@chakra-ui/icons'
import {useSpring, animated} from 'react-spring'
// import { motion } from "framer-motion";

import {animateScroll as scroll} from 'react-scroll'
import styled from '@emotion/styled'

import {useScrollPosition} from '@n8tb1t/use-scroll-position'

import {StyledText} from '../../common/components/Typography'
// import { CreativeLotusTextPath } from "../../common/icons/creativeLotusText";
import {LotivoAnimated} from '../../common/icons/LotivoAnimated'
import {ParticlesBg} from '../../common/components/ParticlesBg'
import {SOCIAL_LINKS} from '../../common'
import './homeStyles.css'

type InfoViewProps = Record<string, unknown>

const renderSocialIcon = (socialProfile: any) => {
  return (
    <Link
      target="_blank"
      href={socialProfile.url}
      title={socialProfile.platform}>
      <Icon
        as={socialProfile.Icon}
        style={{color: 'white', fontSize: '24px'}}
      />
    </Link>
  )
}

export const InfoView = React.forwardRef<HTMLDivElement, InfoViewProps>(
  function InfoView() {
    const spring = useSpring({opacity: 1, from: {opacity: 0}})

    const [hideOnScroll, setHideOnScroll] = React.useState(true)
    console.log('hideOnScroll', hideOnScroll)

    const animatedLogoSize = useBreakpointValue({base: 1, lg: 1.5})

    useScrollPosition(
      ({prevPos, currPos}) => {
        const isShow = currPos.y > -20
        // const isShow = currPos.y > prevPos.y // to show when scrolled up
        if (isShow !== hideOnScroll) setHideOnScroll(isShow)
      },
      [hideOnScroll],
    )

    const socialIcons = (
      <HStack mb={5} spacing={'25px'} style={{zIndex: 1}}>
        {Object.values(SOCIAL_LINKS).map(socialProfile =>
          renderSocialIcon(socialProfile),
        )}
      </HStack>
    )

    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          padding: '20px 0',
          overflow: 'hidden',
        }}>
        <ParticlesBg />
        <animated.div
          style={{
            opacity: spring.opacity.toString(),
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <div style={{}}>
            {/* <MyName /> */}
            {/* <div style={{ margin: "5px 0" }}>My Creative Space</div> */}
            {/* <div style={{ margin: "5px 0" }}> TECHNOLOGY | ART | PHOTOGRAPHY</div> */}
            <LotivoAnimated size={animatedLogoSize} />
            {/* <LotivoAnimated size={screens.lg ? 1.2 : 1} /> */}
          </div>
        </animated.div>

        <Container>
          <Center>{socialIcons}</Center>
        </Container>
        <Box
          style={{
            transition: 'all 500ms',
            opacity: hideOnScroll ? 1 : 0,
            cursor: 'pointer',
            zIndex: 1,
          }}
          onClick={() => scroll.scrollTo(700)}>
          <Box span={16} offset={4}>
            <div style={{marginBottom: '20px', textAlign: 'center'}}>
              <div style={{display: 'inline-block', marginRight: '10px'}}>
                <StyledText type="secondary">
                  Welcome to website of{' '}
                  <Link href={SOCIAL_LINKS.github.url} target="_blank">
                    {/* <StyledText as="span" > */}
                    Pankaj Vaghela
                    {/* </StyledText>! */}
                  </Link>
                </StyledText>
              </div>

              <Box d="inline-block">
                <AnimatedArrow color="white" />
              </Box>
            </div>
          </Box>
        </Box>
      </div>
    )
  },
)

const AnimatedArrow = styled(ArrowDownIcon)`
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`

// const MyName = () => {
//   const props = useSpring({
//     x: 100,
//     from: { x: 0 },
//     config: { duration: 3000 },
//   });

//   return (
//     <div
//       style={{
//         display: "inline-block",
//         borderBottom: "1.5px solid white",
//         borderRadius: "5px",
//         padding: "10px 15px 5px",
//       }}
//     >
//       <animated.svg
//         strokeDashoffset={props.x}
//         width="255"
//         height="40"
//         viewBox="0 0 102 16"
//       >
//         <CreativeLotusTextPath />
//       </animated.svg>
//     </div>
//   );
// };
