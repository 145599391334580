import React from 'react'
import {navigate} from 'gatsby'
import {getQueryVariable} from '../common/utils/url'
// import {useNavigate, RouteComponentProps} from '@reach/router'

// import { Header } from "../../modules/nav/Header";
// import { InfoView } from "./InfoView";
// import {PersonalInfoView} from './PersonalInfoView'

// import {BoxWithBorder} from '../common/components/BoxWithBorder'
import {Box, Divider} from '@chakra-ui/react'
import {InfoView} from '../pagesSections/home/InfoView'
import {PersonalInfoView} from '../pagesSections/home/PersonalInfoView'
import {StyledText} from '../common/components/Typography'
import {ProjectsView} from '../pagesSections/home/ProjectsView'
// import { useSpring, animated } from "react-spring";

// const { Header, Footer, Content, Sider } = Layout;

const HomePage = React.forwardRef(function HomePage() {
  React.useEffect(() => {
    const route = getQueryVariable('route')
    if (route) {
      navigate(route as string)
    }
  }, [navigate])

  return (
    <Box>
      <Box>
        <InfoView />
        {/* <Divider /> */}
        <PersonalInfoView />
        <ProjectsView />
        {/* <BackTop>
          <Button
            type="primary"
            shape="round"
            icon={<ArrowUpOutlined />}
            size="middle"
          />
        </BackTop> */}
      </Box>
      <Box style={{textAlign: 'center'}} py={2}>
        <StyledText type="secondary">
          Handcrafted with ❤️ | © {new Date().getFullYear()} Creative Lotus
        </StyledText>
      </Box>
    </Box>
  )
})

export default HomePage
